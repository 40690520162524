import React from 'react';

function ArtworkImage({ artwork }) {
    console.log(artwork);
    return (
        <img 
          key={artwork.id + artwork.index} 
          src={artwork.imurl[artwork.index]} 
          title={artwork.title}
          alt={artwork.title} 
          loading="lazy" 
        />
    )
}

export default ArtworkImage;
