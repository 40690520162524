import React, { useState } from 'react';
import firebase from '../firebase';
import './CMSLoggin.css';

function CMSLoggin({ setAuth }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const onLogin = (e) => {
        e.preventDefault();
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            // navigate("/")
            console.log(user);
            setAuth(true);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            setError('Invalid username or password');
        });
       
    }

    const displayError = () => {
      if (error) {
        return (
          <div className="cms-login__error">
            {error}
          </div>
        )
      }
    }

    return (
        <div className='cms-login'>                                            
            <form className='login__form'>                                              
                <label htmlFor="email-address">
                    Email address
                </label>
                <input
                    id="email-address"
                    name="email"
                    type="email"                                    
                    required                                                                                
                    placeholder="Email Address"
                    onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="password">
                    Password
                </label>
                <input
                    id="password"
                    name="password"
                    type="password"                                    
                    required                                                                                
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)} />
                {displayError()} 
                <button onClick={onLogin}>Login</button>
            </form>
        </div>
    )
}

export default CMSLoggin;
